var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "check_group" },
      _vm._l(_vm.entryMatterList, function (item) {
        return _c(
          "p",
          { key: item.tenantConfigId },
          [
            _c("span", [_vm._v(_vm._s(item.itemName))]),
            _c("a-switch", {
              attrs: { size: "small" },
              on: {
                change: function ($event) {
                  return _vm.onChange(item)
                },
              },
              model: {
                value: item.status,
                callback: function ($$v) {
                  _vm.$set(item, "status", $$v)
                },
                expression: "item.status",
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-info-header" }, [
      _c("div", { staticClass: "user-info-header-left" }, [
        _c("div", { staticClass: "user-info-header-left-t" }, [
          _vm._v("入职事项管理"),
        ]),
        _c("div", { staticClass: "user-info-header-left-b" }, [
          _vm._v(" 用于配置新员工入职后HR为员工办理的事项 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }