var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emils-seting" },
    [
      _c("div", { staticClass: "v-title" }, [_vm._v("发送邮箱设置")]),
      _c(
        "div",
        { staticClass: "card-list" },
        [
          _vm._l(_vm.emailList, function (item) {
            return _c(
              "a-card",
              { key: item.emailId, staticClass: "antd-card" },
              [
                item.defaultStatus === 1
                  ? _c(
                      "a-tag",
                      { staticClass: "antd-tag", attrs: { color: "#5179FB" } },
                      [_vm._v(" 默认 ")]
                    )
                  : _vm._e(),
                _c(
                  "a-popover",
                  { staticClass: "antd-icon" },
                  [
                    _c(
                      "template",
                      { slot: "content" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "cursor",
                            on: {
                              click: function ($event) {
                                return _vm.openVisible(
                                  "edit",
                                  item.emailId,
                                  item
                                )
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "left",
                              title: "是否确认删除？",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.getDeleteTenantEmail(item.emailId)
                              },
                            },
                          },
                          [_c("p", { staticClass: "del_p" }, [_vm._v("删除")])]
                        ),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "left",
                              title: "是否确认设为默认？",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.getSetDefaultEmail(item.emailId)
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "cursor" }, [
                              _vm._v("设为默认"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("a-icon", {
                      staticClass: "antd-icon",
                      attrs: { type: "ellipsis" },
                    }),
                  ],
                  2
                ),
                _c(
                  "a-card-meta",
                  [
                    _c("template", { slot: "description" }, [
                      _c(
                        "div",
                        { staticClass: "v-content-title" },
                        [
                          _vm._v(_vm._s(item.email)),
                          item.bindStatus === 1
                            ? _c("a-tag", [_vm._v("已绑定")])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "v-send-person" }, [
                        _vm._v("发送人：" + _vm._s(item.sender)),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _c(
            "a-card",
            {
              staticClass: "antd-card-add",
              on: {
                click: function ($event) {
                  return _vm.openVisible("add")
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _c("span", [_vm._v("添加")]),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
      _c(
        "Drawer",
        {
          attrs: {
            title: _vm.title,
            closable: false,
            visible: _vm.visible,
            size: "small",
          },
          on: { close: _vm.onClose, onSubmit: _vm.onSubmin },
        },
        [
          _c("k-form-build", {
            ref: "KFB",
            attrs: { value: _vm.jsonData, "text-display": _vm.textDisplay },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }