var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm._m(0),
    _c("div", { staticClass: "public_margin_bottom" }, [
      _c("div", { staticClass: "top_box title_box" }, [
        _c(
          "span",
          { staticClass: "top_title" },
          [
            _c(
              "BlockTitle",
              { attrs: { title: "欢迎信" } },
              [
                _c("template", { slot: "left" }, [
                  _c("span", { staticClass: "public_box_title_tip" }, [
                    _vm._v("（最多500字符）"),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _vm.welcomeDisabled
          ? _c(
              "span",
              {
                staticClass:
                  "public_color_font_size public_margin_right cursor",
                on: { click: _vm.welcomeDisabledEdit },
              },
              [_vm._v("编辑")]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "public_box" }, [
        _c(
          "div",
          { staticClass: "public_box_textarea" },
          [
            _c("a-textarea", {
              attrs: {
                disabled: _vm.welcomeDisabled,
                placeholder: "请输入",
                "auto-size": { minRows: 5 },
              },
              model: {
                value: _vm.welcomeValue.configContent,
                callback: function ($$v) {
                  _vm.$set(_vm.welcomeValue, "configContent", $$v)
                },
                expression: "welcomeValue.configContent",
              },
            }),
          ],
          1
        ),
        !_vm.welcomeDisabled
          ? _c(
              "div",
              { staticClass: "public_box_button" },
              [
                _c(
                  "a-button",
                  {
                    staticClass: "public_box_button_close",
                    on: { click: _vm.welcomeDisabledClose },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.welcomeDisabledOk },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", [
      _c("div", { staticClass: "top_box public_flex" }, [
        _vm._m(1),
        _c(
          "div",
          [
            _vm.jobGuide.switchCheck
              ? _c(
                  "span",
                  {
                    staticClass: "public_color_font_size cursor",
                    on: { click: _vm.jobGuideAdd },
                  },
                  [
                    _c("svg", { staticClass: "iconpark-icon Circle-Button" }, [
                      _c("use", { attrs: { href: "#add-one" } }),
                    ]),
                    _vm._v(" 添加 "),
                  ]
                )
              : _vm._e(),
            _c("a-switch", {
              staticClass: "public_margin_right public_margin_left",
              attrs: { size: "small" },
              on: { change: _vm.jobGuideChange },
              model: {
                value: _vm.jobGuide.switchCheck,
                callback: function ($$v) {
                  _vm.$set(_vm.jobGuide, "switchCheck", $$v)
                },
                expression: "jobGuide.switchCheck",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.jobGuide.switchCheck
        ? _c(
            "div",
            _vm._l(_vm.needDealt, function (item, index) {
              return _c("div", { key: index, staticClass: "public_box" }, [
                _c("div", { staticClass: "public_box_title" }, [
                  _c(
                    "span",
                    [
                      _c(
                        "BlockTitle",
                        { attrs: { title: "事件" + (index + 1) } },
                        [
                          _c("template", { slot: "left" }, [
                            _c(
                              "span",
                              { staticClass: "public_box_title_tip" },
                              [_vm._v("（最多300字符）")]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    item.needDealtDisabled
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "public_color_font_size public_margin_right cursor",
                            on: {
                              click: function ($event) {
                                return _vm.getNeedDealtEdit(index)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    index !== 0
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "public_color_font_size_del public_margin_right cursor",
                            on: {
                              click: function ($event) {
                                return _vm.delTable(item, index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "public_box_textarea" },
                  [
                    _c("a-textarea", {
                      attrs: {
                        disabled: item.needDealtDisabled,
                        placeholder: "请输入",
                        "auto-size": { minRows: 4 },
                      },
                      model: {
                        value: item.needDealtValue,
                        callback: function ($$v) {
                          _vm.$set(item, "needDealtValue", $$v)
                        },
                        expression: "item.needDealtValue",
                      },
                    }),
                  ],
                  1
                ),
                !item.needDealtDisabled
                  ? _c(
                      "div",
                      { staticClass: "public_box_button" },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "public_box_button_close",
                            on: {
                              click: function ($event) {
                                return _vm.getNeedDealClose(index)
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.getNeedDealOk(item)
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_tips_box" }, [
      _c("p", [_vm._v("小程序入职")]),
      _c("span", { staticClass: "top_tip" }, [
        _vm._v("用于配置新员工入职后首次登陆小程序查看欢迎信及入职指南"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "top_title" }, [_vm._v("入职指南")]),
      _c("span", { staticClass: "top_tip" }, [
        _vm._v("开启后入职指南帮助员工快速办理入职"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }