var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "all-content" }, [
    _c("div", { staticClass: "v-title" }, [_vm._v("工号规则")]),
    _c(
      "div",
      { staticClass: "v-line" },
      [
        _c(
          "a-space",
          [
            _c("span", { staticClass: "v-line-title" }, [
              _vm._v("工号自动生成"),
            ]),
            _c("a-switch", {
              attrs: { size: "small" },
              on: { change: _vm.onChangeSwitch },
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            }),
            _c("span", { staticClass: "v-line-explain" }, [
              _vm._v("开启状态时，批量添加在职员工也会自动生成工号"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm.checked
      ? _c(
          "div",
          [
            _c("a-divider"),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { checked: _vm.staffNumSecondEntryStayData },
                    on: { change: _vm.staffNumSecond },
                  },
                  [_vm._v("离职再入职保留原工号")]
                ),
              ],
              1
            ),
            _c("a-divider"),
            _c("div", { staticClass: "rule-list" }, [
              _c(
                "div",
                [
                  _c("span", { staticClass: "serial_num" }, [_vm._v("序号")]),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool-title",
                      attrs: { placement: "right" },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("前缀与流水号组合需唯一")]),
                      ]),
                      _c(
                        "span",
                        { staticClass: "prefix" },
                        [
                          _vm._v("工号规则前缀"),
                          _c("a-icon", {
                            staticClass: "circle_icon",
                            attrs: {
                              type: "exclamation-circle",
                              theme: "filled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool-title",
                      attrs: { placement: "right" },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("输入流水号位数")]),
                      ]),
                      _c(
                        "span",
                        { staticClass: "number_digit" },
                        [
                          _vm._v("数字位数"),
                          _c("a-icon", {
                            staticClass: "circle_icon",
                            attrs: {
                              type: "exclamation-circle",
                              theme: "filled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool-title",
                      attrs: { placement: "right" },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [
                          _vm._v(
                            "工号前缀+数字位数组合，如QZ+2位数字，生成工号范围QZ01-QZ99"
                          ),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "hover_tips_span",
                          class: [
                            _vm.isLong ? "tool-title" : "tool-title table_span",
                          ],
                        },
                        [
                          _vm._v("工号预览"),
                          _c("a-icon", {
                            staticClass: "circle_icon",
                            attrs: {
                              type: "exclamation-circle",
                              theme: "filled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool-title-long",
                      attrs: { placement: "right" },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("规则之间,聘用形式不能有重叠")]),
                      ]),
                      _c(
                        "span",
                        { staticClass: "hover_tips" },
                        [
                          _vm._v("适用聘用形式"),
                          _c("a-icon", {
                            staticClass: "circle_icon",
                            attrs: {
                              type: "exclamation-circle",
                              theme: "filled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "tool-title" }, [_vm._v("操作")]),
            ]),
            _c("a-divider"),
            _c(
              "div",
              _vm._l(_vm.tableData, function (item, index) {
                return _c("div", { key: index, staticClass: "rule_list" }, [
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "serial_num" }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _c("a-input", {
                        staticClass: "table_input operation",
                        attrs: {
                          "max-length": 10,
                          placeholder: "1-10位字符",
                          "allow-clear": "",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.prefixBlur(index, item.prefix)
                          },
                        },
                        model: {
                          value: item.prefix,
                          callback: function ($$v) {
                            _vm.$set(item, "prefix", $$v)
                          },
                          expression: "item.prefix",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "number_box" },
                        [
                          _c("Number", {
                            staticClass: "table_input",
                            attrs: {
                              "allow-clear": true,
                              min: 1,
                              max: 10,
                              placeholder: "1-10之间整数",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.numLengthBlur(index, item.numLength)
                              },
                            },
                            model: {
                              value: item.numLength,
                              callback: function ($$v) {
                                _vm.$set(item, "numLength", $$v)
                              },
                              expression: "item.numLength",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { class: [_vm.isLong ? "long_span" : "table_span"] },
                        [_vm._v(_vm._s(item.initialValue))]
                      ),
                      _c("a-checkbox-group", {
                        staticClass: "table_input-long",
                        attrs: { options: _vm.tableOptions },
                        model: {
                          value: item.engageModes,
                          callback: function ($$v) {
                            _vm.$set(item, "engageModes", $$v)
                          },
                          expression: "item.engageModes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "cursor del",
                        on: {
                          click: function ($event) {
                            return _vm.delTable(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]),
                ])
              }),
              0
            ),
            _c("div", [
              _c(
                "span",
                {
                  class: [
                    _vm.tableOptions.length === _vm.tableData.length
                      ? "full"
                      : "cursor add-rule",
                  ],
                  on: { click: _vm.addTableList },
                },
                [
                  _c("svg", { staticClass: "iconpark-icon" }, [
                    _c("use", { attrs: { href: "#add-one" } }),
                  ]),
                  _vm._v(" 添加生成规则 "),
                ]
              ),
            ]),
            _c(
              "a-button",
              {
                staticClass: "submitBtn",
                attrs: { type: "primary", disabled: _vm.disabledButton },
                on: { click: _vm.onSave, enterPress: _vm.onSave },
              },
              [_vm._v(" 保存 ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }