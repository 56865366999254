var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-content" },
    [
      _c("div", { staticClass: "v-title" }, [_vm._v("消息设置")]),
      _vm._l(_vm.dataInfo, function (item) {
        return _c(
          "div",
          { key: "info" + item.messageGroupId, staticClass: "info-all" },
          [
            _c(
              "div",
              { staticClass: "info-swit-add" },
              [
                _c(
                  "div",
                  { staticClass: "info-swit" },
                  [
                    _c("span", { staticClass: "info-swit-text" }, [
                      _vm._v(_vm._s(item.groupName)),
                    ]),
                    _c("a-switch", {
                      attrs: {
                        checked: item.groupStatus == 1 ? true : false,
                        size: "small",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onChangeVal.apply(
                            void 0,
                            [item].concat($event)
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-button",
                  {
                    staticClass: "addButtom",
                    attrs: { type: "link", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.handleAdd(item)
                      },
                    },
                  },
                  [
                    _c("svg", { staticClass: "iconpark-icon" }, [
                      _c("use", { attrs: { href: "#add-one" } }),
                    ]),
                    _vm._v("添加 "),
                  ]
                ),
              ],
              1
            ),
            _c("InfoTable", {
              attrs: { "data-info": item.messageConfigList },
              on: {
                getFetchDataApiFn: _vm.getFetchDataApiFn,
                handleEdit: _vm.handleEdit,
              },
            }),
          ],
          1
        )
      }),
      _c("infoForm", {
        ref: "infoForm",
        on: { getFetchDataApiFn: _vm.getFetchDataApiFn },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }