import { render, staticRenderFns } from "./index.vue?vue&type=template&id=61e6dc3f&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=61e6dc3f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e6dc3f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61e6dc3f')) {
      api.createRecord('61e6dc3f', component.options)
    } else {
      api.reload('61e6dc3f', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=61e6dc3f&scoped=true&", function () {
      api.rerender('61e6dc3f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/user/setting/proofTemplate/addTemplate/leftMessage/index.vue"
export default component.exports