var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-template" },
    [
      _c("HeaderBack", {
        staticClass: "add-template-header",
        attrs: {
          title:
            (_vm.tempData.templateType === "edit" ? "编辑" : "新增") +
            "证明模板",
        },
        on: {
          back: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "add-template-content" },
        [
          _c("LeftMessage", {
            ref: "leftMessage",
            attrs: { "label-list": _vm.labelList },
            on: { insert: _vm.insert },
          }),
          _c(
            "div",
            { staticClass: "right-message" },
            [
              _c("Tinymce-vue", {
                attrs: {
                  value: _vm.tempData.tempContent,
                  setting: _vm.setting,
                },
                on: {
                  setups: _vm.setups,
                  input: function (res) {
                    return (_vm.tempData.tempContent = res)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "add-template-bottom" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.addOrEditTemplate },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }