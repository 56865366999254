var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse" },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.staffAddInfo,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "模版名称", prop: "staffName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.staffAddInfo.staffName,
                  callback: function ($$v) {
                    _vm.$set(_vm.staffAddInfo, "staffName", $$v)
                  },
                  expression: "staffAddInfo.staffName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-collapse",
        {
          attrs: { bordered: false, "expand-icon-position": "right" },
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        _vm._l(_vm.labelList, function (item, index) {
          return _c(
            "a-collapse-panel",
            { key: String(index) },
            [
              _c(
                "template",
                { slot: "header" },
                [_c("BlockTitle", { attrs: { title: item.fieldGroupName } })],
                1
              ),
              _c(
                "div",
                { staticClass: "collapse-child" },
                _vm._l(item.fields, function (label, i) {
                  return _c(
                    "a-button",
                    {
                      key: i,
                      on: {
                        click: function ($event) {
                          return _vm.$emit("insert", label)
                        },
                      },
                    },
                    [_vm._v(_vm._s(label.fieldName))]
                  )
                }),
                1
              ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }