var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-table" },
    [
      _c("custom-table", {
        attrs: {
          pagination: false,
          columns: _vm.columns,
          "data-source": _vm.dataInfo,
          "row-key": "id",
        },
        scopedSlots: _vm._u([
          {
            key: "messageStatus",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("a-switch", {
                  attrs: {
                    checked: scope.record.messageStatus == 0 ? false : true,
                    size: "small",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeVal(scope.record)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "remindTimeStrList",
            fn: function (ref) {
              var scope = ref.scope
              return _vm._l(
                scope.record.remindTimeStrList,
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: scope.record.messageGroupId + index,
                      class:
                        scope.record.remindTimeStrList.length != index + 1
                          ? "v-time"
                          : "",
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }
              )
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "a-button",
                  {
                    staticClass: "link",
                    attrs: { type: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEdit(scope.record.messageConfigId)
                      },
                    },
                  },
                  [_vm._v(" 编辑 ")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteItemConfirm(scope)
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }