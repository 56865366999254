// 人员模块-设置-人员信息
import { DICTIONARY } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取自定义字段列表
export async function getFieldGroupList(params) {
  return request(`${DICTIONARY + '/fieldGroup/getFieldGroupList'}`, METHOD.GET, params)
}

// 添加字段组
export async function postCreateFieldGroup(params) {
  return request(`${DICTIONARY + '/fieldGroup/createFieldGroup'}`, METHOD.POST, params)
}

// 字段组配置
export async function postConfigFieldGroup(params) {
  return request(`${DICTIONARY + '/fieldGroup/configFieldGroup'}`, METHOD.POST, params)
}

// 新增自定义字段
export async function postCreateFieldConfig(params) {
  return request(`${DICTIONARY + '/fieldConfig/createFieldConfig'}`, METHOD.POST, params)
}

// 修改字段信息
export async function postUpdateFieldConfig(params) {
  return request(`${DICTIONARY + '/fieldConfig/updateFieldConfig'}`, METHOD.POST, params)
}

// 自定义字段删除
export async function postDeleteFieldConfig(params) {
  return request(`${DICTIONARY + '/fieldConfig/deleteFieldConfig'}`, METHOD.POST, params)
}

// 更新字段组
export async function updateFieldGroup(params) {
  return request(`${DICTIONARY + '/fieldGroup/updateFieldGroup'}`, METHOD.POST, params)
}

// 删除字段组
export async function deleteFieldGroup(params) {
  return request(`${DICTIONARY + '/fieldGroup/deleteFieldGroup'}`, METHOD.POST, params)
}

// 获取模板字段组列表
export async function getFieldGroupOfTemp(params) {
  return request(`${DICTIONARY + '/fieldGroup/getFieldGroupOfTemp'}`, METHOD.GET, params)
}
