var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-content" },
    [
      _c("div", { staticClass: "v-title" }, [_vm._v("试用期设置")]),
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "试用期期限设置：", prop: "probation" } },
            [
              _c(
                "a-space",
                [
                  _c("a-select", {
                    attrs: { placeholder: "请选择", options: _vm.optionsType },
                    model: {
                      value: _vm.form.probation,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "probation", $$v)
                      },
                      expression: "form.probation",
                    },
                  }),
                  _c("span", [_vm._v("个月")]),
                  _vm._v(" "),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", "html-type": "submit" },
                      on: { click: _vm.handleOk },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }