var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-setting-panel" },
    [
      _c(
        "layout",
        {
          ref: "layoutMenu",
          attrs: {
            "menu-data-list": _vm.menuDataList,
            "selected-key": _vm.selectedKey,
          },
          on: {
            "update:selectedKey": function ($event) {
              _vm.selectedKey = $event
            },
            "update:selected-key": function ($event) {
              _vm.selectedKey = $event
            },
            "menu-select-change": _vm.menuSelectChange,
          },
        },
        [_c(_vm.curComponentName, { tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }